<template>
  <div>
    <TitleWrapper
      :filter-option="false"
      title="Dashboard"
      :display-breadcrumb="true"
    ></TitleWrapper>
    <div class="bg-white rounded-lg mt-10">
      <AttendanceGraph />
    </div>
    <div class="rounded-lg">
      <FeeManagerGraph />
    </div>
  </div>
</template>

<script>
import AttendanceGraph from '@views/dashboard/DashboardGraphs/DashboardGraph/CampusAttendanceGraph.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import FeeManagerGraph from '@views/dashboard/DashboardGraphs/DashboardGraph/FeeManagerGraph.vue'

export default {
  components: {
    AttendanceGraph,
    TitleWrapper,
    FeeManagerGraph,
  },
}
</script>
